import payload_plugin_ljKcX9H4Cq from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.30.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_Nw9Rab9MyX from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.15.0_jiti@2.4.2__ior_3leq4mzthvtxwir5pislzlqcwm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KwaLOpT3Z3 from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.15.0_jiti@2.4.2__ior_3leq4mzthvtxwir5pislzlqcwm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_iUOjqY29xN from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.15.0_jiti@2.4.2__ior_3leq4mzthvtxwir5pislzlqcwm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_c12Y0JKoxM from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.15.0_jiti@2.4.2__ior_3leq4mzthvtxwir5pislzlqcwm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_GLtZyBL6kF from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.15.0_jiti@2.4.2__ior_3leq4mzthvtxwir5pislzlqcwm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_h3aF99aGrR from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.15.0_jiti@2.4.2__ior_3leq4mzthvtxwir5pislzlqcwm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_T6wSRJoYqh from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.15.0_jiti@2.4.2__ior_3leq4mzthvtxwir5pislzlqcwm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_50BRjPjiLj from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.30.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/Hangar/Hangar/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_Cq9eucLPya from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.15.0_jiti@2.4.2__ior_3leq4mzthvtxwir5pislzlqcwm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/home/runner/work/Hangar/Hangar/frontend/.nuxt/floating-vue.mjs";
import switch_locale_path_ssr_DjDCBrhYRO from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@nuxtjs+i18n@9.1.1_@vue+compiler-dom@3.5.13_eslint@9.15.0_jiti@2.4.2__magicast@0.3.5_rollup@4_wlesuismdgszllgeglyv6grflq/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_w0B4Gs8LIF from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@nuxtjs+i18n@9.1.1_@vue+compiler-dom@3.5.13_eslint@9.15.0_jiti@2.4.2__magicast@0.3.5_rollup@4_wlesuismdgszllgeglyv6grflq/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import unocss_MzCDxu9LMj from "/home/runner/work/Hangar/Hangar/frontend/.nuxt/unocss.mjs";
import axios_OijlQmfXsA from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/axios.ts";
import dompurify_client_EYdHkZatd0 from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/dompurify.client.ts";
import nprogress_lI8WDWlVpm from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/nprogress.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/sentry.client.ts";
export default [
  payload_plugin_ljKcX9H4Cq,
  revive_payload_client_Nw9Rab9MyX,
  unhead_KwaLOpT3Z3,
  router_iUOjqY29xN,
  payload_client_c12Y0JKoxM,
  navigation_repaint_client_GLtZyBL6kF,
  check_outdated_build_client_h3aF99aGrR,
  chunk_reload_client_T6wSRJoYqh,
  plugin_vue3_50BRjPjiLj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Cq9eucLPya,
  floating_vue_EIcJ7xiw0h,
  switch_locale_path_ssr_DjDCBrhYRO,
  i18n_w0B4Gs8LIF,
  unocss_MzCDxu9LMj,
  axios_OijlQmfXsA,
  dompurify_client_EYdHkZatd0,
  nprogress_lI8WDWlVpm,
  sentry_client_KAXFuL2wum
]