import routerOptions0 from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.15.0_jiti@2.4.2__ior_3leq4mzthvtxwir5pislzlqcwm/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/home/runner/work/Hangar/Hangar/frontend/src/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}